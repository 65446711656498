import * as React from 'react';

import { GridCell } from 'components/shared/grid';
import { getTextWithoutParagraph } from 'utils';

import {
  Wrapper,
  Container,
  ContentWrapper,
  ContentHead,
  Grid,
  ContentItem,
  ContentItemHead,
  ContentItemTitle,
  ContentItemText,
  SellGiftCardsLink,
  SellGiftCardsLinkText,
  SellGiftCardsLinkArrowIcon,
  Title,
} from './about-offices.styles';

export const Offices = (props) => {
  const { title, items, buttonText } = props;

  const gridColumns = items.length > 4 ? 2 : items.length;

  return (
    <Wrapper>
      <Container>
        <ContentWrapper>
          <ContentHead>
            <Title>{title}</Title>
          </ContentHead>
          <Grid
            columns={gridColumns}
            gap={20}
            media={{
              lg: { columns: 2, gap: 50 },
              md: { gap: 30 },
              sm: { gap: 20 },
              xs: { columns: 1 },
            }}
          >
            {items.map(({ title, description, link }) => (
              <GridCell>
                <ContentItem>
                  <ContentItemHead>
                    <ContentItemTitle>{title}</ContentItemTitle>
                    <ContentItemText
                      dangerouslySetInnerHTML={{
                        __html: getTextWithoutParagraph(description),
                      }}
                    />
                  </ContentItemHead>
                  <SellGiftCardsLink href={link} target="_blank">
                    <SellGiftCardsLinkText>{buttonText}</SellGiftCardsLinkText>
                    <SellGiftCardsLinkArrowIcon />
                  </SellGiftCardsLink>
                </ContentItem>
              </GridCell>
            ))}
          </Grid>
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
};
