import * as React from 'react';

import ImageMapSvgUrl from 'assets/images/pages/about/map.url.svg';

import {
  Wrapper,
  Container,
  ContentWrapper,
  ContentHead,
  Content,
  Illustration,
  Title,
  SubTitle,
  MapPinWrapper,
  MapPin1,
  MapPin2,
  MapPin3,
  MapPin4,
  MapPin5,
  MapPin6,
  MapPin7,
  MapPin8,
  MapPin9,
  MapPin10,
  MapPin11,
  MapPin12,
  MapPin13,
} from './about-map.styles';

export const Map = (props) => {
  const { title, description } = props;
  return (
    <Wrapper>
      <Container>
        <ContentWrapper>
          <ContentHead>
            <Title>{title}</Title>
            <SubTitle>{description}</SubTitle>
          </ContentHead>
          <Content>
            <Illustration
              src={ImageMapSvgUrl}
              width={1039}
              height={519}
              loading="lazy"
              alt="World map showing WeGift presence in various locations across North America, Europe, East Asia and Australasia"
            />
            <MapPinWrapper>
              <MapPin1 />
              <MapPin2 />
              <MapPin3 />
              <MapPin4 />
              <MapPin5 />
              <MapPin6 />
              <MapPin7 />
              <MapPin8 />
              <MapPin9 />
              <MapPin10 />
              <MapPin11 />
              <MapPin12 />
              <MapPin13 />
            </MapPinWrapper>
          </Content>
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
};
