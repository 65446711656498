import * as React from 'react';

import TriangleBgSvgUrl1 from 'assets/images/pages/about/intro/about-intro-triangle1.url.svg';
import TriangleBgSvgUrl2 from 'assets/images/pages/about/intro/about-intro-triangle2.url.svg';
import { getTextWithoutParagraph } from 'utils';

import {
  Wrapper,
  Inner,
  StyledTitle,
  Subtitle,
  Description,
  TrianglesWrapper,
  TriangleBg1,
  TriangleBg2,
} from './about-intro.styles';

export const Intro = (props) => {
  const { pageTitle, title, description } = props;

  return (
    <Wrapper>
      <Inner>
        <StyledTitle as="h1" size="sm">
          {pageTitle}
        </StyledTitle>
        <Subtitle as="h2" size="xxl">
          {title}
        </Subtitle>
        <Description
          dangerouslySetInnerHTML={{
            __html: getTextWithoutParagraph(description),
          }}
        />
      </Inner>

      <TrianglesWrapper>
        <TriangleBg1 src={TriangleBgSvgUrl1} alt="" />
        <TriangleBg2 src={TriangleBgSvgUrl2} alt="" />
      </TrianglesWrapper>
    </Wrapper>
  );
};
