import * as React from 'react';

import ImageIsometricCardsSvgUrl from 'assets/images/pages/about/isometric-cards.url.svg';

import {
  Wrapper,
  Container,
  GridKeyStats,
  GridKeyStatsItem,
  Description,
  ImageWrapper,
  Illustration,
  KeyStatsItemWrapper,
  KeyStatsItemValue,
  KeyStatsItemLabel,
  KeyStatsItemIconPlus,
} from './about-desc.styles';

export const Desc = (props) => {
  const { description, keyStats } = props;

  return (
    <Wrapper>
      <Container>
        <Description>{description}</Description>
        <ImageWrapper>
          <Illustration
            src={ImageIsometricCardsSvgUrl}
            width={1019}
            height={559}
            loading="lazy"
            alt="Multiple cards with brands logos on e.g. Nike, Apple"
          />
        </ImageWrapper>
        <GridKeyStats columns={4} gap={85} media={{ md: { columns: 2, gap: 50 }, sm: { gap: 30 } }}>
          <GridKeyStatsItem>
            <KeyStatsItemWrapper>
              <KeyStatsItemValue>
                {keyStats[0].value}
                <KeyStatsItemIconPlus />
              </KeyStatsItemValue>
              <KeyStatsItemLabel>{keyStats[0].title}</KeyStatsItemLabel>
            </KeyStatsItemWrapper>
          </GridKeyStatsItem>

          <GridKeyStatsItem>
            <KeyStatsItemWrapper>
              <KeyStatsItemValue>{keyStats[1].value}</KeyStatsItemValue>
              <KeyStatsItemLabel>{keyStats[1].title}</KeyStatsItemLabel>
            </KeyStatsItemWrapper>
          </GridKeyStatsItem>

          <GridKeyStatsItem>
            <KeyStatsItemWrapper>
              <KeyStatsItemValue>{keyStats[2].value}</KeyStatsItemValue>
              <KeyStatsItemLabel>{keyStats[2].title}</KeyStatsItemLabel>
            </KeyStatsItemWrapper>
          </GridKeyStatsItem>

          <GridKeyStatsItem>
            <KeyStatsItemWrapper>
              <KeyStatsItemValue>{keyStats[3].value}</KeyStatsItemValue>
              <KeyStatsItemLabel>{keyStats[3].title}</KeyStatsItemLabel>
            </KeyStatsItemWrapper>
          </GridKeyStatsItem>
        </GridKeyStats>
      </Container>
    </Wrapper>
  );
};
