import styled from 'styled-components';

import { color, fontSize } from 'components/_settings/variables';

export const Text = styled.div`
  font-size: ${fontSize.sm};
  line-height: 1.75;
  color: ${color.text.light};

  p {
    margin: 0;
  }

  p + p {
    margin-top: 30px;
  }
`;
