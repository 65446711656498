import * as React from 'react';

import { GridCell } from 'components/shared/grid';

import {
  Wrapper,
  Container,
  ContentWrapper,
  ContentHead,
  Grid,
  ContentLeft,
  ContentItem,
  ContentItemTitle,
  ContentItemText,
  Title,
  SubTitle,
} from './about-us.styles';
import { ImageAboutUs } from './images/us';

export const AboutUs = (props) => {
  const { title, description, items } = props;
  return (
    <Wrapper>
      <Container>
        <ContentWrapper>
          <ContentHead>
            <Title>{title}</Title>
            <SubTitle>{description}</SubTitle>
          </ContentHead>
          <Grid columns={2} gap={0} media={{ md: { columns: 1 } }}>
            <GridCell>
              <ContentLeft>
                <ContentItem>
                  <ContentItemTitle>{items[0].title}</ContentItemTitle>
                  <ContentItemText>{items[0].description}</ContentItemText>
                </ContentItem>
                <ContentItem>
                  <ContentItemTitle>{items[1].title}</ContentItemTitle>
                  <ContentItemText>{items[1].description}</ContentItemText>
                </ContentItem>
                <ContentItem>
                  <ContentItemTitle>{items[2].title}</ContentItemTitle>
                  <ContentItemText>{items[2].description}</ContentItemText>
                </ContentItem>
              </ContentLeft>
            </GridCell>
            <GridCell>
              <ImageAboutUs />
            </GridCell>
          </Grid>
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
};
