import styled, { keyframes } from 'styled-components';

import { media, color, fontSize } from 'components/_settings/variables';
import { Container as SharedContainer } from 'components/shared/container';

const Pulsate = keyframes`
0% { transform: scale(0.1, 0.1); opacity: 0.0; }
50% { opacity: 0.3; }
100% { transform: scale(1.5, 1.5); opacity: 0.0; }
`;

export const Wrapper = styled.section`
  background-color: #fbfbfb;
  margin-bottom: 109px;
  ${media.md} {
    margin-bottom: 60px;
  }
  ${media.sm} {
    margin-bottom: 0;
  }
`;

export const Container = styled(SharedContainer)`
  padding-top: 18px;
  padding-bottom: 75px;
  ${media.sm} {
    padding-top: 24px;
    padding-bottom: 43px;
  }
`;

export const Title = styled.h2`
  font-size: 38px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.74;
  letter-spacing: normal;
  margin-bottom: 27px;
  color: ${color.text.primary};
  ${media.sm} {
    font-size: 32px;
  }
`;

export const SubTitle = styled.p`
  font-size: ${fontSize.md}
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  color: #6B7696;
  margin: 0;
  text-align: center;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentHead = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 71px;
  ${media.sm} {
    margin-bottom: 40px;
  }
`;

export const Content = styled.div`
  position: relative;
  padding: 0 69px;
  ${media.md} {
    padding: 0;
  }
`;

export const Illustration = styled.img`
  ${media.lg} {
    width: 100%;
    height: auto;
  }
`;

export const MapPinWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  & i {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      z-index: 1;
      ${media.sm} {
        width: 8px;
        height: 8px;
      }
      ${media.xs} {
        width: 5px;
        height: 5px;
      }
    }
    &::after {
      content: '';
      position: absolute;
      display: block;
      border-radius: 100%;
      height: 30px;
      width: 30px;
      opacity: 0;
      animation: ${Pulsate} 1.5s ease-out infinite;
      ${media.sm} {
        height: 20px;
        width: 20px;
      }
      ${media.xs} {
        height: 15px;
        width: 15px;
      }
    }
    &:nth-child(2) {
      &::after {
        animation-delay: 1.6s;
      }
    }
    &:nth-child(3) {
      &::after {
        animation-delay: 1.7s;
      }
    }
    &:nth-child(4) {
      &::after {
        animation-delay: 1.8s;
      }
    }
    &:nth-child(5) {
      &::after {
        animation-delay: 1.9s;
      }
    }
    &:nth-child(6) {
      &::after {
        animation-delay: 2s;
      }
    }
    &:nth-child(7) {
      &::after {
        animation-delay: 2.1s;
      }
    }
    &:nth-child(8) {
      &::after {
        animation-delay: 2.2s;
      }
    }
    &:nth-child(9) {
      &::after {
        animation-delay: 2.3s;
      }
    }
    &:nth-child(10) {
      &::after {
        animation-delay: 2.4s;
      }
    }
    &:nth-child(11) {
      &::after {
        animation-delay: 2.5s;
      }
    }
    &:nth-child(12) {
      &::after {
        animation-delay: 2.6s;
      }
    }
    &:nth-child(13) {
      &::after {
        animation-delay: 2.7s;
      }
    }
  }
`;

const mapPinColor = {
  purple: '#712ee4',
  blue: '#3b42e0',
  blueLight: '#5d7ad8',
  blueLightLight: '#a8bdff',
};

export const MapPin1 = styled.i`
  top: 41%;
  left: 18.87%;
  &:before {
    background-color: ${mapPinColor.blueLight};
  }
  &:after {
    background-color: ${mapPinColor.blueLight};
  }
`;

export const MapPin2 = styled.i`
  top: 22.9%;
  left: 27.5%;
  &:before {
    background-color: ${mapPinColor.blue};
  }
  &:after {
    background-color: ${mapPinColor.blue};
  }
`;

export const MapPin3 = styled.i`
  top: 32.3%;
  left: 29.7%;
  &:before {
    background-color: ${mapPinColor.purple};
  }
  &:after {
    background-color: ${mapPinColor.purple};
  }
`;

export const MapPin4 = styled.i`
  top: 28.3%;
  left: 45.6%;
  &:before {
    background-color: ${mapPinColor.purple};
  }
  &:after {
    background-color: ${mapPinColor.purple};
  }
`;

export const MapPin5 = styled.i`
  top: 36.5%;
  left: 47.2%;
  &:before {
    background-color: ${mapPinColor.blueLightLight};
  }
  &:after {
    background-color: ${mapPinColor.blueLightLight};
  }
`;

export const MapPin6 = styled.i`
  top: 24.2%;
  left: 49.8%;
  &:before {
    background-color: ${mapPinColor.blueLightLight};
  }
  &:after {
    background-color: ${mapPinColor.blueLightLight};
  }
`;

export const MapPin7 = styled.i`
  top: 15%;
  right: 45.3%;
  &:before {
    background-color: ${mapPinColor.blue};
  }
  &:after {
    background-color: ${mapPinColor.blue};
  }
`;

export const MapPin8 = styled.i`
  top: 32.2%;
  right: 44.3%;
  &:before {
    background-color: ${mapPinColor.purple};
  }
  &:after {
    background-color: ${mapPinColor.purple};
  }
`;

export const MapPin9 = styled.i`
  top: 20.2%;
  right: 39.6%;
  &:before {
    background-color: ${mapPinColor.blue};
  }
  &:after {
    background-color: ${mapPinColor.blue};
  }
`;

export const MapPin10 = styled.i`
  bottom: 34.5%;
  right: 25.5%;
  &:before {
    background-color: ${mapPinColor.purple};
  }
  &:after {
    background-color: ${mapPinColor.purple};
  }
`;

export const MapPin11 = styled.i`
  top: 32.4%;
  right: 19%;
  &:before {
    background-color: ${mapPinColor.blueLightLight};
  }
  &:after {
    background-color: ${mapPinColor.blueLightLight};
  }
`;

export const MapPin12 = styled.i`
  bottom: 18.1%;
  right: 18.1%;
  &:before {
    background-color: ${mapPinColor.blueLightLight};
  }
  &:after {
    background-color: ${mapPinColor.blueLightLight};
  }
`;

export const MapPin13 = styled.i`
  bottom: 13%;
  right: 8%;
  ${media.md} {
    right: 3%;
  }
  &:before {
    background-color: ${mapPinColor.blueLight};
  }
  &:after {
    background-color: ${mapPinColor.blueLight};
  }
`;
