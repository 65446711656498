import * as React from 'react';

import { getFluidImage } from 'utils';

import { Wrapper, Photo, Content, Title, Role } from './about-team-member.styles';

export const Member = ({ className, photo, name, role }) => (
  <Wrapper className={className}>
    <Photo fluid={getFluidImage(photo.localFile)} alt={photo.altText} />
    <Content>
      <Title as="h3" size="sm">
        {name}
      </Title>
      <Role>{role}</Role>
    </Content>
  </Wrapper>
);
