import styled from 'styled-components';

import { fontSize, color, media } from 'components/_settings/variables';
import { Container } from 'components/shared/container';
import { Title } from 'components/shared/title';

export const TrianglesWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${media.sm} {
  }
`;

export const TriangleBg1 = styled.img`
  position: absolute;
  top: 135px;
  left: -37px;
  height: 727px;
  ${media.md} {
    top: 135px;
    left: -65px;
    height: 616px;
  }
  ${media.sm} {
    top: -92px;
    left: -114px;
    height: 585px;
  }
`;

export const TriangleBg2 = styled.img`
  position: absolute;
  top: 125px;
  right: 0;
  height: 630px;
  width: 244px;
  ${media.md} {
    top: 325px;
    right: -66px;
    height: 300px;
    width: auto;
  }
  ${media.sm} {
    top: 266px;
    right: -2px;
    height: 112px;
    width: auto;
  }
`;

export const Description = styled.p`
  max-width: 520px;
  font-size: ${fontSize.sm};
  line-height: 1.75;
  color: ${color.text.primary};
  margin-top: 0;
  margin-right: auto;
  margin-bottom: 35px;
  margin-left: auto;
  strong {
    font-weight: 500;
  }
`;

export const Subtitle = styled(Title)`
  margin-bottom: 45px;
  padding: 0 130px;
  line-height: 1.3;
  ${media.lg} {
    padding: 0 65px;
  }
  ${media.md} {
    padding: 0 35px;
  }
  ${media.sm} {
    padding: 0;
  }
`;

export const StyledTitle = styled(Title)`
  margin-bottom: 13px;
  ${media.sm} {
    font-size: ${fontSize.lg};
    margin-bottom: 12px;
  }
`;

export const Inner = styled(Container)`
  position: relative;
  z-index: 2;
`;

export const Wrapper = styled.section`
  position: relative;
  text-align: center;
  padding-top: 200px;
  padding-bottom: 53px;
  background-color: #fbfbfb;
  ${media.md} {
    padding-top: 160px;
  }
  ${media.sm} {
    padding-top: 145px;
  }
`;
