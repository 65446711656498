import styled from 'styled-components';

import { color, media, transition, fontSize } from 'components/_settings/variables';
import { ArrowRightIcon } from 'components/icons';
import { Container as SharedContainer } from 'components/shared/container';
import { Grid as SharedGrid } from 'components/shared/grid';

export const Grid = styled(SharedGrid)``;

export const Wrapper = styled.section`
  background-color: #eaeef2;
`;

export const Container = styled(SharedContainer)`
  padding-top: 136px;
  padding-bottom: 245px;
  ${media.lg} {
    padding-left: 75px;
    padding-right: 75px;
  }
  ${media.md} {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 100px;
    padding-bottom: 110px;
  }
  ${media.sm} {
    padding-left: 0;
    padding-right: 0;
    padding-top: 30px;
    padding-bottom: 35px;
  }
  ${media.xs} {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

export const Title = styled.h2`
  font-size: 38px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.74;
  letter-spacing: normal;
  margin: 0;
  color: ${color.text.secondary};
`;

export const Text = styled.div`
  font-size: ${fontSize.sm};
  line-height: 1.75;
  color: ${color.text.secondary};
  margin-bottom: 42px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentHead = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 70px;
  ${media.sm} {
    margin-bottom: 33px;
  }
`;

export const ContentItemHead = styled.div``;

export const ContentItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 380px;
  background-color: ${color.text.tertiary};
  padding: 5px 35px;
  box-shadow: 14px 46px 70px -35px rgba(50, 50, 93, 0.27), 2px 2px 22px 0 rgba(26, 26, 67, 0.01);
  ${media.lg} {
    min-height: 300px;
  }
  ${media.xs} {
    min-height: 213px;
  }
`;

export const ContentItemTitle = styled.h3`
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  color: #2c295d;
  margin-bottom: 10px;
`;
export const ContentItemText = styled.p`
  font-size: ${fontSize.sm};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #6b7696;
  margin-top: 0;
`;

export const SellGiftCardsLinkArrowIcon = styled(ArrowRightIcon)`
  g > g {
    stroke: #a6e0ff;
    transition: ${transition.base};
  }
`;

export const SellGiftCardsLinkText = styled.span`
  margin-right: 20px;
`;

export const SellGiftCardsLink = styled.a`
  display: inline-flex;
  align-items: center;
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;
  color: #59b8cd;
  background-color: transparent;
  border: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  margin-bottom: 40px;
  cursor: pointer;
  outline: none;
  transition: ${transition.base};
  ${media.sm} {
    margin-bottom: 34px;
  }
  &:hover {
    color: ${color.primary};

    ${SellGiftCardsLinkArrowIcon} {
      g > g {
        stroke: ${color.primary};
      }
    }
  }
`;
