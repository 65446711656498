import styled from 'styled-components';

import { Container as SharedContainer } from 'components/shared/container';
import { Grid as SharedGrid, GridCell } from 'components/shared/grid';

import { color, fontSize, media } from '../../../_settings/variables';

export const Wrapper = styled.section`
  color: ${color.text.primary};
  padding-bottom: 145px;
  background-color: #fbfbfb;
  ${media.md} {
    padding-bottom: 100px;
  }
  ${media.sm} {
    padding-bottom: 64px;
  }
`;

export const Container = styled(SharedContainer)``;

export const Description = styled.p`
  font-size: 10px;
  font-weight: 600;
  color: #6b7696;
  text-align: center;
  text-transform: uppercase;
  line-height: 1.7;
  letter-spacing: 0.8px;
  margin: 0;
`;

export const ImageWrapper = styled.div`
  text-align: center;
  ${media.md} {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 550px;
  }
  ${media.sm} {
    min-height: 383px;
  }
`;

export const Illustration = styled.img`
  ${media.md} {
    position: absolute;
    top: 0;
    transform: scale(1);
  }
  ${media.sm} {
    top: -51px;
    transform: scale(0.7);
  }
`;

export const GridKeyStats = styled(SharedGrid)`
  padding: 0 29px;
  ${media.sm} {
    padding: 0;
  }
`;

export const GridKeyStatsItem = styled(GridCell)``;

export const KeyStatsItemWrapper = styled.div`
  position: relative;
  padding: 37px 0;
  ${media.sm} {
    padding: 30px 0;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 24px);
    height: 3px;
    background-color: #d9dfe4;
    ${media.md} {
      width: 100%;
    }
  }
`;

export const KeyStatsItemValue = styled.div`
  font-size: 66px;
  color: ${color.text.secondary};
  line-height: 0.67;
  margin-bottom: 24px;
  ${media.sm} {
    font-size: 42px;
    margin-bottom: 47px;
  }
`;

export const KeyStatsItemLabel = styled.div`
  line-height: 1.17;
  font-size: ${fontSize.lg};
  color: #6b7696;
`;

export const KeyStatsItemIconPlus = styled.i`
  font-style: normal;
  margin-left: 15px;
  &::after {
    content: '+';
    width: 31px;
    height: 44px;
    background-image: linear-gradient(325deg, #7f8afc, #00c1de 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;
