import styled from 'styled-components';

import { fontSize, color, media } from 'components/_settings/variables';
import { Container } from 'components/shared/container';
import { Title as TitleShared } from 'components/shared/title';

export const Description = styled.p`
  font-size: ${fontSize.sm};
  line-height: 1.75;
  color: ${color.text.primary};
  margin-top: 0;
  margin-right: auto;
  margin-bottom: 66px;
  margin-left: auto;
  ${media.sm} {
    margin-bottom: 40px;
    br {
      display: none;
    }
  }
  strong {
    font-weight: 500;
    border-bottom: 6px solid #defbff;
  }
`;

export const Title = styled(TitleShared)`
  font-size: 38px;
  margin-bottom: 28px;
  padding: 0 130px;
  color: ${color.text.secondary};
  ${media.sm} {
    font-size: 32px;
    padding: 0;
    margin-bottom: 26px;
  }
`;

export const Inner = styled(Container)``;

export const Wrapper = styled.section`
  position: relative;
  text-align: center;
  margin-bottom: 79px;
  ${media.sm} {
    margin-bottom: 65px;
  }
`;
