import styled from 'styled-components';

import { media, color, fontSize } from 'components/_settings/variables';
import { Container as SharedContainer } from 'components/shared/container';
import { Grid as SharedGrid } from 'components/shared/grid';

import { Text } from '../about.styles';

export const Wrapper = styled.section`
  position: relative;
  margin-top: -30px;
  margin-bottom: 140px;
  z-index: 1;
  ${media.sm} {
    margin-bottom: 60px;
  }
`;

export const Container = styled(SharedContainer)`
  max-width: 1140px;
`;

export const Grid = styled(SharedGrid)`
  padding: 0 52px;
  ${media.md} {
    padding: 0 40px;
  }
  ${media.sm} {
    padding: 0 24px;
  }
  :nth-child(1) {
    margin-bottom: 37px;
  }
  :nth-child(2) {
    margin-bottom: 102px;
    ${media.md} {
      margin-bottom: 72px;
    }
  }
`;

export const ContentWrapper = styled.div`
  padding-top: 67px;
  padding-bottom: 48px;
  border-radius: 12px;
  background-color: ${color.text.tertiary};
  box-shadow: 14px 46px 70px -35px rgba(50, 50, 93, 0.27), 2px 2px 22px 0 rgba(26, 26, 67, 0.01);
  ${media.sm} {
    padding-bottom: 79px;
  }
`;

export const ContentLeftBrands = styled.div`
  text-align: center;
  ${media.sm} {
    margin-bottom: 32px;
  }
`;

export const IllustrationBrands = styled.img`
  margin-top: -16px;
  margin-left: -89px;
  ${media.md} {
    margin-left: -65px;
    width: 85%;
  }
  ${media.sm} {
    margin-left: 0;
    width: 100%;
    height: auto;
  }
`;

export const ContentLeftPartners = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-left: 34px;
  padding-right: 50px;
  grid-row-gap: 27px;
  ${media.lg} {
    padding-left: 0;
  }

  ${media.sm} {
    padding: 0;
    margin-bottom: 78px;
  }
`;

export const IllustrationPartners = styled.img`
  position: relative;
  ${media.md} {
    width: 100%;
  }
`;

export const IllustrationPartnersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  :nth-child(1) ${IllustrationPartners} {
    top: 5px;
    max-width: 129px;
  }
  :nth-child(2) ${IllustrationPartners} {
    top: 5px;
    max-width: 61px;
    ${media.xs} {
      max-width: 41px;
    }
  }
  :nth-child(3) ${IllustrationPartners} {
    top: 2px;
    max-width: 72px;
    ${media.xs} {
      max-width: 52px;
    }
  }
  :nth-child(4) ${IllustrationPartners} {
    max-width: 112px;
  }
  :nth-child(5) ${IllustrationPartners} {
    top: 2px;
    max-width: 51px;

    ${media.xs} {
      max-width: 31px;
    }
  }
  :nth-child(6) ${IllustrationPartners} {
    top: -7px;
    max-width: 92px;
  }
`;

export const ContentLeftCases = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-left: 56px;
  padding-right: 127px;
  margin-top: -17px;
  grid-row-gap: 17px;

  ${media.lg} {
    padding-left: 42px;
    padding-right: 85px;
  }

  ${media.md} {
    margin-top: 5px;
    padding-left: 0;
    padding-right: 15px;
  }

  ${media.sm} {
    margin-top: -32px;
    margin-bottom: 35px;
  }

  ${media.xs} {
    padding: 0;
  }
`;

export const CaseItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CaseItemImage = styled.img``;

export const CaseItemName = styled.span`
  font-size: 12px;
  color: #878d9e;
  margin-top: 10px;
`;

export const ContentItemLinkName = styled.h3`
  font-size: ${fontSize.lg};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #7f7acf;
  margin: 0 0 12px;
`;

export const ContentItemText = styled(Text)`
  color: ${color.text.secondary};
`;

export const ContentRight = styled.div``;
