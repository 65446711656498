import { graphql } from 'gatsby';
import * as React from 'react';

import Layout from 'components/_settings/layout';
import { Footer } from 'components/blocks/footer';
import { Header } from 'components/blocks/header';
import { Desc } from 'components/pages/about/desc';
import { Infrastructure } from 'components/pages/about/infrastructure';
import { Intro } from 'components/pages/about/intro';
import { Journey } from 'components/pages/about/journey';
import { Map } from 'components/pages/about/map';
import { Offices } from 'components/pages/about/offices';
import { Partners } from 'components/pages/about/partners';
import { Team } from 'components/pages/about/team';
import { AboutUs } from 'components/pages/about/us';

import { buildPageMetadata } from '../utils/data-builders';
import { extractItems } from '../utils/extract-items';

const About = (props) => {
  const {
    data: {
      wpPage,
      wpPage: { acf },
    },
  } = props;

  const keyStats = extractItems(acf, 'keyStat');
  const whoUsItems = extractItems(acf, 'section4Item');
  const partnerItems = extractItems(acf, 'section5Item');
  const teamMembers = extractItems(acf, 'section7Member');

  return (
    <Layout pageMetadata={buildPageMetadata(wpPage)}>
      <Header />
      <Intro
        pageTitle={acf.pageNameTitle}
        title={acf.sectionIntroTitle}
        description={acf.sectionIntroDescription}
      />
      <Desc description={acf.keyStatsTitle} keyStats={keyStats} />
      <Infrastructure
        title={acf.section2Title}
        description={acf.section2Description}
        text={acf.section2Text}
        textTwo={acf.section2Text2}
        textThree={acf.section2Text3}
      />
      <Map title={acf.section3Title} description={acf.section3Description} />
      <AboutUs title={acf.section4Title} description={acf.section4Description} items={whoUsItems} />
      <Partners items={partnerItems} />
      <Journey
        title={acf.section6Title}
        description={acf.section6Description}
        buttonText={acf.section6ButtonText}
        buttonLink={acf.section6ButtonLink}
      />
      <Team members={teamMembers} />
      <Offices
        title={acf.aboutOffices.title}
        items={acf.aboutOffices.items}
        buttonText={acf.aboutOffices.buttonText}
      />
      <Footer theme="light" withoutBorderTop />
    </Layout>
  );
};

export default About;

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      ...wordpressPagePageMetadata
      acf {
        pageNameTitle
        sectionIntroTitle
        sectionIntroDescription
        keyStatsTitle
        keyStat1Title
        keyStat1Value
        keyStat2Title
        keyStat2Value
        keyStat3Title
        keyStat3Value
        keyStat4Title
        keyStat4Value
        section2Title
        section2Description
        section2Text
        section2Text2
        section2Text3
        section3Title
        section3Description
        section4Title
        section4Description
        section4Item1Title
        section4Item1Description
        section4Item2Title
        section4Item2Description
        section4Item3Title
        section4Item3Description
        section5Item1Title
        section5Item1Link
        section5Item1Description
        section5Item2Title
        section5Item2Link
        section5Item2Description
        section5Item3Title
        section5Item3Link
        section5Item3Description
        section6Title
        section6Description
        section6ButtonText
        section6ButtonLink
        section7Member1Photo {
          id
          altText
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        section7Member1Name
        section7Member1Role
        section7Member2Photo {
          id
          altText
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        section7Member2Name
        section7Member2Role
        section7Member3Photo {
          id
          altText
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        section7Member3Name
        section7Member3Role
        section7Member4Photo {
          id
          altText
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        section7Member4Name
        section7Member4Role
        section7Member5Photo {
          id
          altText
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        section7Member5Name
        section7Member5Role
        aboutOffices {
          title
          buttonText
          items {
            title
            description
            link
          }
        }
        section9Title
      }
    }
  }
`;
