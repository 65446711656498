import styled from 'styled-components';

import rectangleImg from 'assets/images/pages/about/rectangle@3x.png';
import { media, color, fontSize } from 'components/_settings/variables';
import { Container as SharedContainer } from 'components/shared/container';

export const Wrapper = styled.section`
  position: relative;
  background-color: #fbfbfb;
  padding-bottom: 116px;
  ${media.md} {
    padding-bottom: 30px;
  }
  ${media.sm} {
    padding-bottom: 0;
  }
  &:after {
    content: '';
    position: absolute;
    top: 13px;
    right: 0;
    width: 107px;
    height: 364px;
    background-image: url(${rectangleImg});
    background-size: contain;
  }
`;

export const Container = styled(SharedContainer)`
  padding-top: 24px;
  @media (min-width: 1365px) {
    max-width: 1305px;
  }
`;

export const Title = styled.h2`
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  margin: 0 0 45px;
  color: ${color.text.primary};
  ${media.sm} {
    margin: 0 0 21px;
  }
`;

export const Description = styled.p`
  font-size: ${fontSize.lg};
  font-weight: 300;
  line-height: 1.38;
  color: #798fd6;
  margin: 0 0 40px;
`;

export const Text = styled.div`
  font-size: ${fontSize.sm};
  line-height: 1.75;
  color: ${color.text.secondary};
  ${media.xl} {
    br {
      display: none;
    }
  }
  p {
    margin: 0 0 42px;
  }
`;
export const TextTwo = styled.div`
  font-size: ${fontSize.sm};
  line-height: 1.75;
  color: ${color.text.secondary};
  margin: 0 0 8px;
  p {
    margin: 0 0 8px;
  }
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 83px;
  ${media.lg} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }
  ${media.md} {
    grid-template-columns: 12fr;
  }
`;

export const ContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 79px;
  padding-right: 66px;
  ${media.xl} {
    padding-right: 0;
  }
  ${media.lg} {
    padding-left: 0;
  }
`;

export const ContentRight = styled.div`
  z-index: 1;
`;

export const Illustration = styled.img`
  margin-top: -39px;
  ${media.lg} {
    width: 100%;
    height: auto;
    margin-top: 0;
  }
`;
