import * as React from 'react';

import { Button } from 'components/shared/button';
import { getTextWithoutParagraph } from 'utils';

import { Wrapper, Inner, Title, Description } from './about-journey.styles';

export const Journey = (props) => {
  const { title, description, buttonText, buttonLink } = props;

  return (
    <Wrapper>
      <Inner>
        <Title as="h2">{title}</Title>
        <Description
          dangerouslySetInnerHTML={{
            __html: getTextWithoutParagraph(description),
          }}
        />
        <Button href={buttonLink} theme="light" as="a">
          {buttonText}
        </Button>
      </Inner>
    </Wrapper>
  );
};
