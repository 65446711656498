import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import * as React from 'react';
import styled from 'styled-components';

import { media } from 'components/_settings/variables';
import { getFluidImage } from 'utils';

const Image = styled(GatsbyImage)`
  max-width: 100%;
  height: 100%;
  ${media.md} {
    max-height: 530px;
  }
  ${media.sm} {
    max-height: 450px;
  }
  ${media.xs} {
    max-height: 248px;
  }
`;

export const ImageAboutUs = () => {
  const { file } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "pages/about/we-gift-group-photo@3x.png" }) {
        childImageSharp {
          fluid(maxWidth: 693) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return <Image fluid={getFluidImage(file)} imgStyle={{ objectPosition: 'top' }} />;
};
