import * as React from 'react';

import ImageInfrastructureSvgUrl from 'assets/images/pages/about/diagram-2-a.url.svg';
import { getTextWithoutParagraph } from 'utils';

import {
  Wrapper,
  Container,
  ContentWrapper,
  ContentLeft,
  ContentRight,
  Illustration,
  Title,
  Description,
  Text,
  TextTwo,
} from './about-infrastructure.styles';

export const Infrastructure = (props) => {
  const { title, description, text, textTwo, textThree } = props;
  return (
    <Wrapper>
      <Container>
        <ContentWrapper>
          <ContentLeft id="digital-infrastructure">
            <Title>{title}</Title>
            <Description
              dangerouslySetInnerHTML={{
                __html: getTextWithoutParagraph(description),
              }}
            />
            <Text
              dangerouslySetInnerHTML={{
                __html: getTextWithoutParagraph(text),
              }}
            />
            <TextTwo
              dangerouslySetInnerHTML={{
                __html: getTextWithoutParagraph(textTwo),
              }}
            />
            <Text
              dangerouslySetInnerHTML={{
                __html: getTextWithoutParagraph(textThree),
              }}
            />
          </ContentLeft>
          <ContentRight>
            <Illustration
              src={ImageInfrastructureSvgUrl}
              width={630}
              height={506}
              loading="lazy"
              aria-describedby="digital-infrastructure"
              alt="WeGift Infrastructure flow diagram"
            />
          </ContentRight>
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
};
