import * as React from 'react';

import { GridCell } from 'components/shared/grid';

import {
  Wrapper,
  Container,
  Grid,
  ContentWrapper,
  ContentLeftBrands,
  IllustrationBrands,
  ContentLeftPartners,
  IllustrationPartnersWrapper,
  IllustrationPartners,
  ContentLeftCases,
  CaseItem,
  CaseItemImage,
  CaseItemName,
  ContentRight,
  ContentItemLinkName,
  ContentItemText,
} from './about-partners.styles';
import BrandPartnersSvgUrl from './icons/brand-partners.url.svg';
import PartnerSvgUrl5 from './icons/cb-myworld-faded.url.svg';
import PartnerSvgUrl3 from './icons/eon.url.svg';
import PartnerSvgUrl2 from './icons/sky-faded.url.svg';
import PartnerSvgUrl6 from './icons/sodexo-faded.url.svg';
import CaseSvgUrl1 from './icons/use-case-1.url.svg';
import CaseSvgUrl2 from './icons/use-case-2.url.svg';
import CaseSvgUrl3 from './icons/use-case-3.url.svg';
import CaseSvgUrl4 from './icons/use-case-4.url.svg';
import CaseSvgUrl5 from './icons/use-case-5.url.svg';
import CaseSvgUrl6 from './icons/use-case-6.url.svg';
import PartnerSvgUrl4 from './icons/uswitch.url.svg';
import PartnerSvgUrl1 from './icons/vodafone-faded.url.svg';

export const Partners = (props) => {
  const { items } = props;

  return (
    <Wrapper>
      <Container>
        <ContentWrapper>
          <Grid columns={2} gap={0} media={{ sm: { columns: 1 } }}>
            <GridCell>
              <ContentLeftBrands>
                <IllustrationBrands
                  src={BrandPartnersSvgUrl}
                  width={319}
                  height={202}
                  loading="lazy"
                  alt="Various WeGift partners' logos e.g. Amazon, Vodafone"
                />
              </ContentLeftBrands>
            </GridCell>
            <GridCell>
              <ContentRight>
                <ContentItemLinkName>{items[0].title}</ContentItemLinkName>
                <ContentItemText>{items[0].description}</ContentItemText>
              </ContentRight>
            </GridCell>
          </Grid>
          <Grid columns={2} gap={0} media={{ sm: { columns: 1 } }}>
            <GridCell>
              <ContentLeftPartners>
                <IllustrationPartnersWrapper>
                  <IllustrationPartners
                    src={PartnerSvgUrl1}
                    width={129}
                    height={46}
                    loading="lazy"
                    alt="Vodafone"
                  />
                </IllustrationPartnersWrapper>
                <IllustrationPartnersWrapper>
                  <IllustrationPartners
                    src={PartnerSvgUrl2}
                    width={61}
                    height={37}
                    loading="lazy"
                    alt="Sky"
                  />
                </IllustrationPartnersWrapper>
                <IllustrationPartnersWrapper>
                  <IllustrationPartners
                    src={PartnerSvgUrl3}
                    width={72}
                    height={52}
                    loading="lazy"
                    alt="Eon"
                  />
                </IllustrationPartnersWrapper>
                <IllustrationPartnersWrapper>
                  <IllustrationPartners
                    src={PartnerSvgUrl4}
                    width={112}
                    height={51}
                    loading="lazy"
                    alt="USwitch"
                  />
                </IllustrationPartnersWrapper>
                <IllustrationPartnersWrapper>
                  <IllustrationPartners
                    src={PartnerSvgUrl5}
                    width={51}
                    height={47}
                    loading="lazy"
                    alt="CB Myworld"
                  />
                </IllustrationPartnersWrapper>
                <IllustrationPartnersWrapper>
                  <IllustrationPartners
                    src={PartnerSvgUrl6}
                    width={92}
                    height={30}
                    loading="lazy"
                    alt="Sodexo"
                  />
                </IllustrationPartnersWrapper>
              </ContentLeftPartners>
            </GridCell>
            <GridCell>
              <ContentRight>
                <ContentItemLinkName>{items[1].title}</ContentItemLinkName>
                <ContentItemText>{items[1].description}</ContentItemText>
              </ContentRight>
            </GridCell>
          </Grid>
          <Grid columns={2} gap={0} media={{ sm: { columns: 1 } }}>
            <GridCell>
              <ContentLeftCases>
                <CaseItem>
                  <CaseItemImage src={CaseSvgUrl1} width={70} height={70} loading="lazy" alt="" />
                  <CaseItemName>Acquisition</CaseItemName>
                </CaseItem>
                <CaseItem>
                  <CaseItemImage src={CaseSvgUrl5} width={70} height={70} loading="lazy" alt="" />
                  <CaseItemName>Retention</CaseItemName>
                </CaseItem>
                <CaseItem>
                  <CaseItemImage src={CaseSvgUrl4} width={70} height={70} loading="lazy" alt="" />
                  <CaseItemName>Loyalty</CaseItemName>
                </CaseItem>
                <CaseItem>
                  <CaseItemImage src={CaseSvgUrl3} width={70} height={70} loading="lazy" alt="" />
                  <CaseItemName>Activation</CaseItemName>
                </CaseItem>
                <CaseItem>
                  <CaseItemImage src={CaseSvgUrl2} width={70} height={70} loading="lazy" alt="" />
                  <CaseItemName>Referral</CaseItemName>
                </CaseItem>
                <CaseItem>
                  <CaseItemImage src={CaseSvgUrl6} width={70} height={70} loading="lazy" alt="" />
                  <CaseItemName>Refunds</CaseItemName>
                </CaseItem>
              </ContentLeftCases>
            </GridCell>
            <GridCell>
              <ContentRight>
                <ContentItemLinkName>{items[2].title}</ContentItemLinkName>
                <ContentItemText>{items[2].description}</ContentItemText>
              </ContentRight>
            </GridCell>
          </Grid>
        </ContentWrapper>
      </Container>
    </Wrapper>
  );
};
