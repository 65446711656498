import styled from 'styled-components';

import { media, color, fontSize } from 'components/_settings/variables';
import { Container as SharedContainer } from 'components/shared/container';
import { Grid as SharedGrid } from 'components/shared/grid';

export const Wrapper = styled.section``;

export const Container = styled(SharedContainer)`
  padding-top: 18px;
  @media (min-width: 1440px) {
    max-width: 1356px;
  }
`;

export const Grid = styled(SharedGrid)`
  background-color: ${color.primary};
  > div {
    ${media.md} {
      flex-direction: column-reverse;
    }
  }
`;

export const Title = styled.h2`
  font-size: 38px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.74;
  letter-spacing: normal;
  margin-bottom: 27px;
  color: ${color.text.primary};
`;

export const SubTitle = styled.p`
  font-size: ${fontSize.md}
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  color: #6B7696;
  margin: 0;
  text-align: center;
`;

export const Text = styled.div`
  font-size: ${fontSize.sm};
  line-height: 1.75;
  color: ${color.text.secondary};
  margin-bottom: 42px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentHead = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 63px;
  ${media.sm} {
    margin-bottom: 40px;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 693px;
  background-color: ${color.primary};
`;

export const ContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 87px 0 98px;
  ${media.xl} {
    padding: 50px;
  }
  ${media.lg} {
    padding: 40px 30px 30px 40px;
  }
  ${media.sm} {
    padding: 26px 30px 30px 40px;
  }
`;

export const ContentItem = styled.div`
  margin-bottom: 35px;
  ${media.sm} {
    margin-bottom: 34px;
  }
`;
export const ContentItemTitle = styled.h3`
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: ${color.text.tertiary};
  margin: 0 0 15px;
  ${media.sm} {
    font-size: ${fontSize.lg};
    margin: 0 0 10px;
  }
`;
export const ContentItemText = styled.p`
  font-size: ${fontSize.sm};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.83);
  margin: 0;
`;
