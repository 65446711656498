import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';

import { fontSize, transition, color, media } from 'components/_settings/variables';
import { Title as SharedTitle } from 'components/shared/title';

export const Content = styled.div`
  position: absolute;
  top: 28px;
  left: 37px;
  opacity: 0;
  transition: ${transition.base};
  ${media.sm} {
    display: none;
  }
`;

export const Title = styled(SharedTitle)`
  font-size: ${fontSize.lg};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: ${color.text.tertiary};
`;

export const Role = styled.span`
  font-size: ${fontSize.sm};
  color: rgba(255, 255, 255, 0.5);
`;

export const Photo = styled(GatsbyImage)`
  max-width: 100%;
  height: 100%;
`;

export const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  &:hover {
    ${Content} {
      opacity: 1;
    }
  }
`;
