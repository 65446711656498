import * as React from 'react';

import { Wrapper, Container, MembersWrapperOne, MembersWrapperTwo } from './about-team.styles';
import { Member } from './member';

export const Team = ({ members }) => (
  <Wrapper>
    <Container>
      <MembersWrapperOne>
        <Member photo={members[0].photo} name={members[0].name} role={members[0].role} />
        <Member photo={members[1].photo} name={members[1].name} role={members[1].role} />
      </MembersWrapperOne>
      <MembersWrapperTwo>
        <Member photo={members[2].photo} name={members[2].name} role={members[2].role} />
        <Member photo={members[3].photo} name={members[3].name} role={members[3].role} />
        <Member photo={members[4].photo} name={members[4].name} role={members[4].role} />
      </MembersWrapperTwo>
    </Container>
  </Wrapper>
);
