import styled from 'styled-components';

import { media } from 'components/_settings/variables';
import { Container as SharedContainer } from 'components/shared/container';
import { Title } from 'components/shared/title';

export const Container = styled(SharedContainer)`
  @media (min-width: 1400px) {
    max-width: 1364px;
  }
`;

export const MembersWrapperOne = styled.div`
  display: grid;
  grid-template-columns: 1fr 797px;
  grid-template-rows: 500px;
  grid-gap: 15px;
  margin-bottom: 15px;
  ${media.xl} {
    grid-template-columns: 1fr 60%;
    grid-gap: 10px;
    margin-bottom: 10px;
  }
  ${media.md} {
    grid-template-rows: 330px;
  }
  ${media.sm} {
    grid-template-rows: 130px;
    grid-gap: 5px;
    margin-bottom: 5px;
  }
`;

export const MembersWrapperTwo = styled.div`
  display: grid;
  grid-template-columns: 1fr 557px 431px;
  grid-template-rows: 520px;
  grid-gap: 15px;
  ${media.xl} {
    grid-template-columns: 1fr 40% 28%;
    grid-gap: 10px;
  }
  ${media.md} {
    grid-template-rows: 330px;
  }
  ${media.sm} {
    grid-template-rows: 130px;
    grid-gap: 5px;
  }
`;

export const StyledTitle = styled(Title)`
  text-align: center;
  margin-bottom: 15px;

  ${media.sm} {
    margin-bottom: 20px;
  }
`;

export const Wrapper = styled.section`
  margin-bottom: 15px;
`;
